// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'

import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette && !CMS) {
    $.nette.init()
}

import { iOS, IE, CMS } from './core/config'
import ScrollService from './services/Scroll'

if (IE) {
    document.body.classList.add('is-explorer')
}

if (iOS) {
    document.body.classList.add('is-iphone')
}

if (CMS || IE) {
    ScrollService.useNative()
} else {
    ScrollService.useVirtual()
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import Component from './core/Component'
import { loadComponents, removeComponents } from './core/componentUtils';

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'

import Swup from 'swup';
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import SwupJsPlugin from '@swup/js-plugin'
//import swupGtmPlugin from 'swup/plugins/swupGtmPlugin';
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'

if (!(CMS && IE && iOS)) {
    const jsPluginOptions = [
        {
            from: '(.*)',
            to: '(.*)',
            in: next => next(),
            out: next => next()
        },
        {
            from: '(.*)',
            to: 'article-detail',
            in: next => next(),
            out: next => {
                const articleardAnimation = Component.getFromElement('article-card-animation')

                if (articleardAnimation) {
                    articleardAnimation.animate().then(next)
                } else {
                    next()
                }
            }
        }
    ]

    const scrollStack = []

    function pushScrollPosition() {
        const location = window.location.href
        const offset = ScrollService.getScroll().y

        scrollStack.push({
            location,
            offset
        })

        if (scrollStack > 10) {
            scrollStack.shift()
        }
    }

    function popScrollPosition() {
        return scrollStack.pop()
    }

    let currentScroll = null

    const swup = new Swup({
        LINK_SELECTOR: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([data-component]):not([data-ref]), a[href^="#"]:not([data-no-swup]):not([data-component]):not([data-ref])',
        containers: ['#page-content', '#modals', '#sidebar'],
        animationSelector: '.PageWrapper',
        debugMode: false,
        doScrollingRightAway: false,
        animateScroll: true,
        preload: true,
        cache: true,
        plugins: [new SwupBodyClassPlugin(), new SwupJsPlugin(jsPluginOptions)],
        skipPopStateHandling: event => {
            if (event?.state?.source === 'swup') {
                return false
            }
            return true
        }
    });

    swup.on('clickLink', event => {
        pushScrollPosition()
        if (event.delegateTarget.dataset.rel === 'articleCard') {
            const articleCardAnimation = Component.getFromElement('article-card-animation')
            articleCardAnimation.parse(event.delegateTarget)
            event.delegateTarget.classList.add('is-transitioning')
        }

        if (event.delegateTarget.dataset.rel === 'articleMain') {
            const articleCardAnimation = Component.getFromElement('article-card-animation')
            articleCardAnimation.parse(event.delegateTarget.closest('[data-main-article]'))
        }

        EventBus.emit('swup:clicklink', { event })
    })

    // components
    swup.on('willReplaceContent', event => {
        document.querySelectorAll('[data-swup]').forEach(element => {
            removeComponents(element);
        });
    });

    swup.on('contentReplaced', event => {
        Nette.reinit()

        const id = window.location.hash.replace('#', '')
        const anchor = document.getElementById(id)

        queryAll('[data-swup]').forEach(element => loadComponents(element))

        if (currentScroll) {
            setTimeout(() => {
                ScrollService.setPosition(0, currentScroll.offset)
                currentScroll = null
            }, 0)
        } else if (id && anchor && !document.classList.contains('to-category')) {
            const box = anchor.getBoundingClientRect()

            ScrollService.setPosition(0, box.top + ScrollService.getScroll().y)
        } else {
            ScrollService.setPosition(0, 0)
        }

        if ($.nette) {
            $.nette.load()
        }

        EventBus.emit('swup:aftercontentchange')
    })

    swup.on('transitionEnd', event => {
        EventBus.emit('swup:transitionend')
    })

    swup.on('popState', event => {
        const settings = popScrollPosition()
        if (settings?.location == window.location.href) {
            currentScroll = settings
        }
    })

    window.swup = swup
}

EventBus.on('nette:ajax:complete', () => {
    ScrollService.resize()
})

loadComponents();